import axios from 'axios';
import authHeader from './auth-header';
import constants from '../constants';
import { parseError } from '@/utils/utils'
var API_URL = constants.API_URL + 'report/';

class ReportService {

    async createReport(data) {
      try {
        let response = await axios.post(API_URL + 'createReport', data, { headers: authHeader() });
        return response;
      }
      catch (error) {
        return parseError(error);
      }
    }

    async softDeleteReport(reportId, userId) {
      try {
        let response = await axios.post(API_URL + 'softDeleteReport', { reportId: reportId, userId: userId }, { headers: authHeader() });
        return response;
      }
      catch (error){
        return parseError(error);
      }
    }

    saveReportPriority(reportId, newInstallationPriority) {
      return new Promise((resolve, reject) => {
        try {
          console.log("Sending saveReportPriority", reportId, newInstallationPriority)
              axios.post(
              API_URL + 'saveReportPriority', 
              { sitereportid : reportId, changes: { newInstallationPriority: newInstallationPriority } },
              { headers: authHeader() })
              .then((response) => {
                console.log("Received response", response)
                resolve(response);
              })
              .catch((err) => {
                
                console.log("Received err", err)
                reject(err);
              });
        } catch (error) {
          reject(error);
        }
      });
    }

    saveReportChanges(reportId, changes, locale, language) {
      return new Promise(( resolve, reject ) => {
          axios.post(API_URL + 'saveReportChanges', { sitereportid : reportId, changes: changes, locale: locale, language: language }, { headers: authHeader() }).then((response) => {
            resolve(response)
          }).catch((err) => {
            reject(err)
          })
      })
    }
}

export default new ReportService();